import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import { sharingAPI } from "../../api/TimestampAPI";

import { themes } from "../../theme/themes";
import moment from "moment";
import { THEME } from "../../Settings";
import { useTranslation } from "react-i18next";
import { LanguageSelectModal } from "../../components/LanguageSelect";
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import OtpInput from "../../components/Otp/OtpInput";
import { useIDPContext } from "@copyrightdelta/drx-frontend-api";
import ExternalFileDisplay from "./components/ExternalFileDisplay";
import SharedLinkNotFound from "./components/SharedLinkNotFound";


const validateTokenAgainstShareId = (token, shareid) => {
  const metadata = token?.access_token_parsed?.drx_data?.otp_metadata;
  const roles = token?.access_token_parsed?.drx_data?.roles;
  if (metadata?.sharing_id === shareid && roles?.includes("visitor")) {
    return true;
  }
  return false;
}

const validateUserTokenAgainstEmail = (token, email) => {
  const tokenEmail = token?.access_token_parsed?.email

  // if any are undefined, return false
  if (email === undefined || tokenEmail === undefined) {
    return false;
  }

  if (email === tokenEmail) {
    return true;
  }
  return false;
}

const ExternalShare: React.FC = () => {
  const { user } = useIDPContext()
  var { shareId } = useParams();

  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);
  const [sharedBy, setSharedBy] = useState(null);
  const [dateShared, setDateShared] = useState(null);
  const [dateExpires, setDateExpires] = useState(null);
  const [notes, setNotes] = useState(null);
  const [email, setEmail] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);

  const themeName = THEME

  // check that share id matched the sharing id in the token
  const authorisedVisitor = validateTokenAgainstShareId(user?.token, shareId);
  const authorisedUser = validateUserTokenAgainstEmail(user?.token, email);

  // TODO: If user has a token and accesses and link that is not theirs, show a warning

  const theme = themes[themeName];
  const { t } = useTranslation();

  const getFileInfo = async () => {
    try {
      var urldata = await sharingAPI.getSharedFile(shareId, user?.token?.access_token);
      setUrl(urldata.url);
      setName(urldata.name);
      setEmail(urldata.email);
      setSharedBy(urldata.shared_by);
      setDateShared(urldata.date_shared);
      setDateExpires(urldata.date_expires);
      setDateShared(urldata.date_shared);
      setDateExpires(urldata.date_expires);
      setNotes(urldata.notes);
    } catch (error) {
      if (error.response.status === 404) {
        setNotFound(true);
      } else if (error.response.status === 403) {
        setForbidden(true);
      }
    }
  }

  useEffect(() => {
    document.documentElement.style.height = '100%';
    document.body.style.height = '100%';
    // Check if theme starts with "rgb" or "#", if so, set background color to white
    if (theme.sharebg.startsWith("rgb") || theme.sharebg.startsWith("#")) {
      document.body.style.backgroundColor = theme.sharebg;
    } else {
      document.body.style.backgroundImage = `url(${theme.sharebg})`;
    }

  }, []);

  useEffect(() => {
    (async () => {
      await getFileInfo();
      if (authorisedVisitor || authorisedUser) {
        // send tracking data
        await sharingAPI.trackSharedFileAccess(shareId);
      }
    })();
  }, [authorisedVisitor, authorisedUser]);




  return (
    <ThemeProvider theme={theme?.theme}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
      }} >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "background.paper",
            height: "60px",
            minHeight: "60px",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={theme?.logo} alt="" style={{ maxHeight: "30px" }} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {notFound && (<SharedLinkNotFound />)}
          {(!authorisedVisitor && !authorisedUser) && !notFound && (
            <Box sx={{ my: 4, p: 2, bgcolor: "white", borderRadius: 2, textAlign: "center", width: { xs: "90%", lg: "50%" }, maxWidth: "1000px", }}>
              <OtpInput shareId={shareId} email={email} />
            </Box>
          )}
          {(url && (authorisedVisitor || authorisedUser)) && !notFound && (
            <>
              <Box sx={{ width: { xs: "90%", lg: "50%" }, maxWidth: "1000px", m: "0 auto" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 3,
                        fontWeight: "600",
                        marginBottom: "10px",
                        fontSize: "16px",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      {t('shared_by', { name: sharedBy, date: moment.utc(dateShared).local().format("LLL") })}
                    </Typography>
                    {notes && (<Box sx={{ display: "flex", flexDirection: "row", my: 3, gap: 1 }}>
                      <FormatQuoteRoundedIcon sx={{ color: "white" }} />
                      <Typography
                        variant="body1"
                        color={"white"}
                        textAlign={"left"}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >{notes}</Typography>
                    </Box>)}
                    {dateExpires && (
                      <Typography variant="body1" sx={{ color: "white", textAlign: "left", mb: 1, fontSize: "14px", }}>
                        {t('link_expires_on', { date: moment.utc(dateExpires).local().format("LLL") })}
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(227, 236, 251, 1)",
                        fontSize: "14px",
                        textAlign: "left",
                        mb: 2
                      }}
                    >
                      {t('timestamped_by', { name: theme?.name })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <ExternalFileDisplay url={url} name={name} />
              </Box>
            </>
          )}

        </Box>
        <Box sx={{ position: "fixed", bottom: "10px", right: "10px", ml: "auto", backgroundColor: "white", borderRadius: 1 }}>
          <LanguageSelectModal />
        </Box>
      </Box>

    </ThemeProvider>
  );
};

export default ExternalShare;
