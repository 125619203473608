const hostname = window.location.hostname;

const getSettings = async () => {
    // force to not use cache
    const cacheBuster = new Date().getTime()
    const url = `${process.env.REACT_APP_ENV_CONFIG_URL}/${hostname}?t=${cacheBuster}`
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching remote config:", error);
        return {};
    }
}

// let settings;

const initializeSettings = async () => {
    let settings
    if (hostname === "localhost") {
        settings = {
            API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
            THEME: process.env.REACT_APP_THEMENAME,
            IDP_PROVIDER_URL: process.env.REACT_APP_IDP_PROVIDER_URL,
        }
    } else {
        settings = await getSettings()
    }
    return settings
}

const settings = await initializeSettings()


// TODO: This needs to move somewhere else and implemented properly
export const IDP_PROVIDER_URL = settings.IDP_PROVIDER_URL || 'http://localhost:4005'
export const API_ENDPOINT = settings.API_ENDPOINT
export const THEME = settings.THEME
export const FAVICON = settings.FAVICON || `${process.env.PUBLIC_URL}/cpd-favicon.png`
export const TITLE = settings.TITLE || "Provenance | Copyright Delta"

// update favicon and title
document.title = TITLE
const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement
favicon.href = FAVICON
const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement
metaDescription.content = TITLE