import React, { useContext, useEffect, useState } from 'react'
import { useGetSharingLinks } from '../api/sharinglinks/getSharingLinks';
import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, Paper } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import moment from 'moment';
import TableLink from '../components/Table/Link';
import Pagination from '../components/Pagination';
import { TabContext, TabPanel } from '@mui/lab';
import SortableHeading from '../components/Table/SortableHeading';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MoreVert } from '@mui/icons-material';
import SharingLinkMenu from '../components/SharingLinkMenu';
import { sharingAPI } from '../api/TimestampAPI';
import { useTranslation } from 'react-i18next';
import { renderExpiryDate } from '../Helper';

const boolToIcon = (bool: boolean) => {
    return bool ? <CheckCircleRoundedIcon color="success" /> : <CancelRoundedIcon color="error" />
}

const SharingLinks = () => {
    const { t } = useTranslation();
    const [activePageCount, setActivePageCount] = useState(0);
    const [inactivePageCount, setInactivePageCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [inactivePage, setInactivePage] = useState(1);
    const [selectedTab, setSelectedTab] = useState('1');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [searchValue, setSearchValue] = useState('');
    const [currentSearchValue, setCurrentSearchValue] = useState('');
    const [selectedSharedLink, setSelectedSharedLink] = useState(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const perPage = 10;

    const { error: getActiveLinksError, data: activeLinks } = useGetSharingLinks({ page: activePage, perPage, active: true, sortBy: sortConfig.key, sortDir: sortConfig.direction, search: currentSearchValue });
    const { error: getInactiveLinksError, data: inactiveLinks } = useGetSharingLinks({ page: inactivePage, perPage, active: false, sortBy: sortConfig.key, sortDir: sortConfig.direction, search: currentSearchValue });

    useEffect(() => {
        if (activeLinks) {
            setActivePageCount(Math.ceil(activeLinks.count / perPage))
        }

        if (inactiveLinks) {
            setInactivePageCount(Math.ceil(inactiveLinks.count / perPage))
        }
    }, [activeLinks, inactiveLinks]);

    const handleTabSelect = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    const handleActivePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setActivePage(value);
    };
    const handleInactivePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setInactivePage(value);
    };

    const handleSearch = () => {
        setCurrentSearchValue(searchValue);
    }

    const handleCancelSearch = () => {
        setCurrentSearchValue('');
        setSearchValue('');
    }

    const isLinkExpired = (link: any) => {
        return moment.utc(link.date_expires).isBefore(moment.utc());
    }

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, sharedLink) => {
        setAnchorEl(event.currentTarget);
        setSelectedSharedLink(sharedLink);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedSharedLink(null);
    };

    const handleDelete = async () => {
        // TODO: Use React Query instead once implemented
        const creationId = selectedSharedLink?.creation_version.creation_id;
        const version = selectedSharedLink?.creation_version.version;
        await sharingAPI.unshareFile(
            creationId,
            version,
            selectedSharedLink.id
        );
        window.location.reload();
        handleClose();
    };

    const renderSearchForm = () => {
        return (
            <Box sx={{ width: { xs: "100%", md: "50%" }, my: 4 }}>
                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                    <TextField
                        fullWidth
                        focused
                        size={"small"}
                        id="outlined-basic"
                        variant="outlined"
                        value={searchValue}
                        placeholder={t("shared_links_search_form.placeholder")}
                        onChange={(e) => setSearchValue(e.target.value)}
                        InputProps={{
                            endAdornment: currentSearchValue && <InputAdornment position="end">
                                <IconButton
                                    aria-label="cancel search"
                                    onClick={handleCancelSearch}
                                    edge="end"
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch} startIcon={<SearchRoundedIcon />}>{t("search")}</Button>
                </Stack>
            </Box>
        )
    }

    const renderTable = (links: any, type: string) => {
        return (
            <TableContainer>
                <Table aria-label="shared links table" component={Paper}>
                    <TableHead>
                        <TableRow>
                            <SortableHeading keyProp="project_name" value={t("project")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <SortableHeading keyProp="creation_version_name" value={t("creation")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <TableCell>{t("version")}</TableCell>
                            <SortableHeading keyProp="shared_email" value={t("shared_with")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <SortableHeading keyProp="date_shared" value={t("date_shared")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <SortableHeading keyProp="date_expires" value={t("expires")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            {type === "inactive" &&
                                <>
                                    <TableCell>{t("expired")}</TableCell>
                                    <TableCell>{t("revoked")}</TableCell>
                                </>
                            }
                            <TableCell>{t("viewed")}</TableCell>
                            <TableCell align='right'>{t("view_count")}</TableCell>

                            {type === "active" && <TableCell></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {links?.results?.map((link, index) => (
                            <TableRow key={link?.id}>
                                <TableCell>
                                    <TableLink href={`/projects/${link?.creation_version.creation.project_id}/`}>
                                        {link?.creation_version.creation.project.name}
                                    </TableLink>
                                </TableCell>
                                <TableCell>
                                    <TableLink href={`/projects/${link?.creation_version.creation.project_id}/${link?.creation_version.creation_id}/${link?.creation_version.version}`}>
                                        {link?.creation_version.name}
                                    </TableLink>
                                </TableCell>
                                <TableCell>
                                    {link?.creation_version.version}
                                </TableCell>
                                <TableCell>
                                    {link?.shared_email}
                                </TableCell>
                                <TableCell>
                                    {moment.utc(link?.date_shared).local().format("LLL")}
                                </TableCell>
                                <TableCell>
                                    {renderExpiryDate(link?.date_expires, t)}
                                </TableCell>
                                {type === "inactive" && <>
                                    <TableCell align='center'>
                                        {boolToIcon(isLinkExpired(link))}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {boolToIcon(link?.revoked)}
                                    </TableCell></>
                                }
                                <TableCell align='center'>
                                    {boolToIcon(link?.viewed)}
                                </TableCell>
                                <TableCell align='right'>
                                    {link?.view_count}
                                </TableCell>
                                {type === "active" && <TableCell align='center'>
                                    <IconButton
                                        aria-label="options"
                                        onClick={(e) => handleMenuClick(e, link)}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            <Typography
                variant="h5"
                sx={{
                    fontWeight: "600",
                    margin: "15px 0px",
                }}
            >
                {t("shared_links")}
            </Typography>
            {renderSearchForm()}
            <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleTabSelect}>
                        <Tab label={`${t("active_links")} (${activeLinks?.count})`} value="1" sx={{ textTransform: 'none' }} />
                        <Tab label={`${t("revoked_expired_links")} (${inactiveLinks?.count})`} value="2" sx={{ textTransform: 'none' }} />
                    </Tabs>
                </Box>

                <TabPanel value="1" sx={{ p: 1 }}>
                    {
                        getActiveLinksError ?
                            <Alert severity="error">{t('active_links_failed_to_load')}</Alert> :
                            activeLinks?.results.length == 0 ? <Typography variant="subtitle1">
                                {t('no_active_links')}</Typography> :
                                renderTable(activeLinks, "active")
                    }
                    <Pagination count={activePageCount} onChange={handleActivePageChange} />
                </TabPanel>

                <TabPanel value="2" sx={{ p: 1 }}>
                    {
                        getInactiveLinksError ?
                            <Alert severity="error">{t('revoked_expired_links_failed_to_load')}</Alert> :
                            inactiveLinks?.results.length == 0 ? <Typography variant="subtitle1">
                                {t('no_revoked_expired_links')}</Typography> :
                                renderTable(inactiveLinks, "inactive")
                    }
                    <Pagination count={inactivePageCount} onChange={handleInactivePageChange} />
                </TabPanel>
            </TabContext>
            <SharingLinkMenu anchorEl={anchorEl} handleClose={handleClose} selectedShare={selectedSharedLink} handleDelete={handleDelete} />
        </Box >
    );
};

export default SharingLinks
